import * as React from "react"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import {Col, Container, Row} from 'react-bootstrap'
import {Trans} from 'gatsby-plugin-react-i18next';

import MapIcon from '../../static/map.png';


const Projects = ({ data }) => {
  const projects = data.allContentfulProject.edges
  return (
    <Layout title="Varmaorka Projects">
      <div className="py-offset">
        <Container>
          <Row className="align-items-center mb-md-5 mb-4">
            <Col md={12}>
              <h2 className="section-title section-title-inner"><Trans>Projects</Trans></h2>
            </Col>
          </Row>

          <Row>
            {projects.map((project, index) => {
              return (
                <Col md={4} className="mb-5" key={index}>
                  <Link to={project.node.slug} className="card-image d-block card-image-lg">
                    <GatsbyImage image={project.node.image.gatsbyImageData} alt={project.node.image.title} />
                    <div className="card-image-overlay">
                      <p>{project.node.locationLatlong} <img src={MapIcon} alt="map icon" /></p>
                      <h3>{project.node.name}</h3>
                      <p>{project.node.startedText} <br />{project.node.finishedText}</p>
                    </div>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
query($language: String!){
  allContentfulProject (sort: {fields: [order], order: DESC}, filter: {node_locale: {eq: $language}}) {
    edges {
      node {
        slug
        name
        locationLatlong
        startedText
        finishedText
        image {
          gatsbyImageData
        }
        order
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default Projects
